// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contents-js": () => import("./../src/pages/contents.js" /* webpackChunkName: "component---src-pages-contents-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-secret-js": () => import("./../src/pages/secret.js" /* webpackChunkName: "component---src-pages-secret-js" */),
  "component---src-templates-chapter-js": () => import("./../src/templates/chapter.js" /* webpackChunkName: "component---src-templates-chapter-js" */)
}

